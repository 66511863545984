.react-contexify {
	box-shadow: 0 2px 8px rgba(0,0,0,.3), 0 0 0 1px #eee;
	padding: 8px 0;
}

.context-menu {
	background: var(--primary-light);;
	position: fixed;
	z-index: 1010;
}

.context-menu .context-menu-item > :first-child {
	padding: 8px 16px;
}

.context-menu .context-menu-item:hover > :first-child{
	background: var(--neutral-20p);
	color: var(--neutral-80p)
}