@font-face {
  font-family: InterUI-Medium;
  src: url('../assets/fonts/Inter-UI-Medium.otf');
}

@font-face {
  font-family: InterUI-Regular;
  src: url('../assets/fonts/Inter-UI-Regular.otf');
}

@font-face {
  font-family: InterUI-SemiBold;
  src: url('../assets/fonts/Inter-UI-SemiBold.otf');
}

@font-face {
  font-family: InterUI-Medium;
  src: url('../assets/fonts/Inter-UI-Medium.otf');
}

html {
  height: 100%;
}

html > * {
  font-family: 'InterUI-Regular';
}

body {
  overflow: auto;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

:root {
  --primary-orange: #FCB00D;
  --primary-blue: #49C1F5;
  --primary-drak-blue: #4F5B6B;
  --primary-light: white;
  --orange-shade-dark:#DE9906;
  --orange-shade-medium: #FCB00D;
  --orange-shade-lighter: #FFDB8D;
  --blue-shade-dark: #3BA6D5;
  --blue-shade-medium: #49C1F5;
  --blue-shade-light: #92DAF9;
  --secondary-light-orange: #F28A57;
  --secondary-green: #0ca60c;
  --secondary-light-red:  #e96c7b;
  --secondary-red-lighter: #ef5163;
  --secondary-red: #e64053;
  --primary-red: #d8142b;
  --secondary-red-dark: #a81a2b;
  --secondary-red-darker: #aa0000;
  --secondary-red-darkest: #700808;
  --secondary-yellow: #fed700;
  --secondary-yellow-lighter: #ffe4b5;
  --secondary-blue: #6B27AA;
  --neutral-90p: #1e1f1c;
  --neutral-80p: #232323;
  --neutral-70p: #343434;
  --neutral-60p: #565656;
  --neutral-50p: #848484;
  --neutral-40p: #c6c6c6;
  --neutral-30p: #d8d8d8;
  --neutral-20p: #f2f2f2;
}

h1 {
  font-family: 'InterUI-SemiBold';
  font-size: 48px;
  color: var(--neutral-80p);
  line-height: 48px;
}

h2 {
  font-family: 'InterUI-SemiBold';
  font-size: 36px;
  color: var(--neutral-80p);
  line-height: 42px;
}

h3 {
  font-family: 'InterUI-Medium';
  font-size: 28px;
  color: var(--neutral-80p);
  line-height: 34px;
}

h4 {
  font-family: 'InterUI-SemiBold';
  font-size: 20px;
  color: var(--neutral-80p);
  line-height: 26px;
}

h5 {
  font-family: 'InterUI-SemiBold';
  font-size: 18px;
  color: var(--neutral-80p);
  line-height: 22px;
}

h6 {
  font-family: 'InterUI-Medium';
  font-size: 16px;
  color: var(--neutral-80p);
  line-height: 22px;
}

.dots-menu {
  fill: var(--neutral-60p);
}

.dots-menu:hover * {
  fill: white;
  color: white;
}

.share-icon {
  fill: var(--blue-shade-medium);
}

.share-icon:hover {
  color: white;
  fill: white;
}

.target-icon {
  fill: var(--neutral-70p);
}

.target-icon:hover {
  color: white;
  fill: white;
}

.filter-icon {
  fill: var(--neutral-70p);
}

.filter-icon:hover {
  color: white;
  fill: white;
}

button.gm-ui-hover-effect {
  display: none !important;
}

::-webkit-scrollbar-track
{
	border-radius: 0px;
  background-color: #D8D8D8;
}

::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 0px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #969696;
}