.react-datepicker {
	background: #FFFFFF;
	box-shadow: 0 0 4px 0 rgba(0,0,0,0.36);
	border: 0;
}


.react-datepicker__triangle {
	border: 0;
	display: none;
}

.react-datepicker__navigation {
	margin-top: 24px;
}

.react-datepicker__navigation--previous {
	border-right-color: var(--neutral-80p)
}

.react-datepicker__navigation--next {
	border-left-color: var(--neutral-80p)
}

.react-datepicker__input-container {
	height:44px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.react-datepicker__input-container > input {
	color: var(--neutral-80p);
    font-size: 16px;
    padding: 0 0.875rem;
	height:42px;
	width: 100%;
	border-style: groove;
    border: 1px solid var(--neutral-30p);
}

.react-datepicker__input-container > input:disabled {
	border:1px solid var(--neutral-20p);
	color: var(--neutral-50p);
}

.react-datepicker__input-container > input:focus {
	outline: none;
	color: var(--neutral-90p);
}

.react-datepicker__input-container > input::-webkit-input-placeholder, 
.react-datepicker__input-container > input:-ms-input-placeholder, 
.react-datepicker__input-container > input::placeholder  { 
	font-family: InterUI-Regular;
	font-size: 16px;
	line-height: 16px;
	color: var(--neutral-50p);
}

.react-datepicker__day--keyboard-selected {
	border-radius: 0;
	background: var(--blue-shade-dark);
}
.react-datepicker__day:hover, 
.react-datepicker__day--in-range, 
.react-datepicker__day--in-range:hover {
	border-radius: 0;
	font-family: InterUI-Regular;
	font-size: 15px;
	color: var(--neutral-80p);
	text-align: center;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 102px;
}

.react-datepicker-wrapper {
	display: inline;
}

.react-datepicker__day--in-range {
	background: var(--blue-shade-dark);
	color: white;
}

.react-datepicker__day--selected {
	background: var(--blue-shade-dark);
	color: white;
	border-radius: 0;
}

.react-datepicker__day--in-selecting-range {
	border-radius: 0;
	background: var(--blue-shade-medium);
	color: white;
}

.react-datepicker__day--selecting-range-start:hover {
	background: var(--blue-shade-medium);
	color: white;
}

.react-datepicker__day--selecting-range-end:hover {
	background: var(--blue-shade-medium);
	color: white;
}

.react-datepicker__day--keyboard-selected:hover {
	background: var(--blue-shade-medium);
	color: white;
}

.react-datepicker__header {
	background: white;
	border: 0;
}

.react-datepicker__day-name {
	font-family: InterUI-SemiBold;
	font-size: 15px;
	color: var(--neutral-80p);
	text-align: center;
	line-height: 15px;
}

.react-datepicker__day-names {
	padding: 30px 0 10px 0;
}

.react-datepicker__current-month {
	font-family: InterUI-SemiBold;
	font-size: 18px;
	color: var(--neutral-80p);
	text-align: center;
	line-height: 18px;
}

.react-datepicker__month-container {
	padding: 24px 14px;
}